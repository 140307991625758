import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Constants from '../../utils/constants';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(14, 14, 14);
  height: 100vh;
`;

const NavigationBar = styled.div`
  position: fixed;
  top: 2vh;
  z-index: 2;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Button = styled.a`
  margin: 0 5px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FrameImage = styled.img`
  width: 90%;
  height: 90%;
  display: block;
  margin-top: 10vh;
  cursor: zoom-in;
  transition: transform 0.25s ease;

  &.zoomed-in {
    cursor: zoom-out;
  }
`;

const FrameViewer = () => {
  const location = useLocation();
  const [isZoomed, setIsZoomed] = useState(false);
  const imageRef = useRef(null);

  // Extract query parameters from the URL
  const searchParams = new URLSearchParams(location.search);
  const track = searchParams.get('track');
  const time = parseFloat(searchParams.get('time'));

  // Function to handle zoom in and out
  const handleZoom = (event) => {
    const img = imageRef.current;
    if (!img) return;

    // Calculate the click position relative to the image
    const rect = img.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    const offsetY = event.clientY - rect.top;

    if (!isZoomed) {
      const scale = 4;
      // Calculate the zoom origin as a percentage of the image dimensions
      const xPercent = (offsetX / rect.width) * 100;
      const yPercent = (offsetY / rect.height) * 100;
      // Set the transform origin to the click position
      img.style.transformOrigin = `${xPercent}% ${yPercent}%`;
      img.style.transform = `scale(${scale})`;
    } else {
      img.style.transformOrigin = 'center center';
      img.style.transform = 'scale(1)';
    }
    // Toggle the zoom state
    setIsZoomed(!isZoomed);
  };

  // Construct the URL for the frame image
  const imageUrl = `${Constants.API_BASE_URL}/geovideo/get-original-frame/?track=${track}&time=${time}`;

  return (
    <Container>
      <NavigationBar>
        <Button href={`?track=${track}&time=${Math.max(0, time - 1)}`}>Frame Anterior (-1s)</Button>
        <Button href={`?track=${track}&time=${time + 1}`}>Frame Seguinte (+1s)</Button>
      </NavigationBar>
      <ImageContainer>
        <FrameImage
          ref={imageRef}
          src={imageUrl}
          alt="Video frame"
          onClick={handleZoom}
          className={isZoomed ? 'zoomed-in' : ''}
        />
      </ImageContainer>
    </Container>
  );
};

export default FrameViewer;