import React, { useEffect, useState } from 'react';
import Split from 'react-split';
import styled, { css } from 'styled-components';
import Map from '../components/Map';
import { Logo } from '../components/presentational';
import SideMenu from '../containers/SideMenuContainer';

const WrapperDiv = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
`;

const StyledMap = styled(Map)`
  height: 100%;
  width: 100%;
`;

const StyledSplit = styled(Split)`
  width: 100%;

  .gutter.gutter-vertical {
      background: #000;
      background-clip: padding-box;
      border-top: 5px solid hsla(0,0%,100%,0);
      border-bottom: 5px solid hsla(0,0%,100%,0);
      cursor: row-resize;
      width: 100%;

      &:hover {
        border-top: 5px solid rgba(0, 0, 0, 0.5);
        border-bottom: 5px solid rgba(0, 0, 0, 0.5);
      }

      ${props => props.disabled && css`
        display: none;
      `}
   }
`;

const GeovideoDiv = styled.div``;

// TODO: this is no longer a presentational component
const MapContainer = props => {
  const [_paneSize, _setPanSize] = useState(
    (localStorage.getItem('split-sizes') ? JSON.parse(localStorage.getItem('split-sizes')) :
      [25, 75]));

  const [geovideoEnabled, setGeovideoEnabled] = useState(false);

  useEffect(() => {
    const videoObserver = new MutationObserver(([mutation,]) => {
      setGeovideoEnabled(mutation.target.children.length > 0);
    });

    videoObserver.observe(document.getElementById('geovideo-widget'), {
      attributes: false,
      characterData: false,
      childList: true,
      subtree: false,
      attributeOldValue: false,
      characterDataOldValue: false
    });

    return () => {
      videoObserver.disconnect();
    };

  }, []);

  return (
    <React.Fragment>
      <WrapperDiv>
        <StyledSplit
          disabled={!geovideoEnabled}
          direction="vertical"
          sizes={geovideoEnabled ? _paneSize : [0, 100]}
          minSize={0}
          gutterSize={11}
          onDragEnd={(sizes) => {
            localStorage.setItem('split-sizes', JSON.stringify(sizes));
            _setPanSize(sizes);
          }}
        >
          <GeovideoDiv id="geovideo-widget" />
          <StyledMap {...{ ...props, _size: geovideoEnabled ? _paneSize[1] : null }} />
        </StyledSplit>
        <SideMenu />
      </WrapperDiv>
      {process.env.REACT_APP_SHOW_LOGO === 'true' && <Logo />}
    </React.Fragment>);
};


export default MapContainer;
