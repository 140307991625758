import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { transform } from 'ol/proj';
import { EXTRA_SMALL_MAX } from '../presentational/mediaBreakpoints';

const CoordinatesDisplay = styled.input`
  flex-grow: 2;
  border: none;
  padding: 5px 10px;
  background: inherit;
  outline: none;
  text-align: center;
  color: black;
  font-size: 0.9em;
  border-right: 1px outset;

  @media(max-width: ${EXTRA_SMALL_MAX}) {
    font-size: 0.8em;
  }
`;


const roundDecimalCoords = {
  'EPSG:3763': (coords) => {
    return coords.map((coord) => coord.toFixed(2));
  },
  'EPSG:4326':(coords) => {
    const shiftedCoordinates = [coords[1], coords[0]];
    return shiftedCoordinates.map((coord) => coord.toFixed(6));
  },
  'EPSG:3857':(coords) => {
    const shiftedCoordinates = [coords[1], coords[0]];
    return shiftedCoordinates.map((coord) => coord.toFixed(6));
  },
};

function MapCoordinates({ map }) {
  const [mapCoordinates, setMapCoordinates] = useState('');
  const currentProjection = useSelector((state) => state.map.currentProjection);

  useEffect(() => {
    if (!map) return;
    const handleMouseMove = (event) => {
      const coords = map.getEventCoordinate(event.originalEvent);
      let transformedCoords = transform(coords, map.getView().getProjection().getCode(), currentProjection.name);
      transformedCoords = roundDecimalCoords[currentProjection.name](transformedCoords);
      setMapCoordinates(transformedCoords.map((coord) => coord.toString()).join(', '));
    };

    map.on('pointermove', handleMouseMove);

    return () => {
      map.un('pointermove', handleMouseMove);
    };
  }, [map, currentProjection]);

  return <CoordinatesDisplay value={mapCoordinates} readOnly />;
}

export default MapCoordinates;
