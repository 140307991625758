import { createSlice } from '@reduxjs/toolkit';
import { fetchCarTrack } from '../thunks/geoVideoThunk';

const initialState = {
  isFetching: false,
  mapSummary: null,
  carTrack: null,
  visualizationProject: null,
  error: false,
  coordinates: null,
};

const geovideoSlice = createSlice({
  name: 'geovideo',
  initialState,
  reducers: {
    clearAutoOpenSettings: (state, action) => {
      state.mapSummary = null;
      state.carTrack = null;
      state.visualizationProject = null;
    },
    setCoordinates: (state, action) => {
      state.coordinates = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCarTrack.pending, (state) => {
      state.isFetching = true;
      state.error = false;
    })
      .addCase(fetchCarTrack.fulfilled, (state, action) => {
        state.isFetching = false;
        state.mapSummary = action.payload.map_summary;
        state.carTrack = action.payload.car_track;
        state.visualizationProject = action.payload.visualization_project;
        state.error = false;
      })
      .addCase(fetchCarTrack.rejected, (state) => {
        state.isFetching = false;
        state.error = true;
        // reset State variable
        state.mapSummary = null;
        state.carTrack = null;
        state.visualizationProject = null;
      });
  },
});

export const { clearAutoOpenSettings, setCoordinates } = geovideoSlice.actions;
export default geovideoSlice.reducer;
