import { useState } from 'react';
import PropTypes from 'prop-types';
import './TimeOffsetTool.scss';

const TimeOffsetTool = ({ videoOffsets, onOffsetChange, availablePositions, activeGoPro }) => {
  const [isOpen, setIsOpen] = useState(false);

  const incrementOffset = (position, increment) => {
    const currentOffset = videoOffsets[position] || 0;
    const newOffset = Math.round((currentOffset + increment) * 10) / 10; // Round to 1 decimal place
    onOffsetChange(position, newOffset);
  };

  return (
    <div className="offset-panel">
      <button onClick={() => setIsOpen(!isOpen)}>{isOpen ? 'Esconder' : ''} Sincronização de Vídeo</button>
      {isOpen && (
        <div className="offset-controls">
          {availablePositions.map((position) => (
            <div key={position} className="offset-control">
              <span className="offset-label">{activeGoPro[position]}:</span>
              <span className="offset-value">{(videoOffsets[position] || 0).toFixed(1)}s</span>
              <button onClick={() => incrementOffset(position, 0.1)}>+</button>
              <button onClick={() => incrementOffset(position, -0.1)}>-</button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

TimeOffsetTool.propTypes = {
  videoOffsets: PropTypes.object.isRequired,
  onOffsetChange: PropTypes.func.isRequired,
  availablePositions: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeGoPro: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default TimeOffsetTool;
