import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';

import axios from 'axios';
import styled from 'styled-components';

import videojs from 'video.js';
import 'videojs-vr';
import 'video.js/dist/video-js.css';
import 'videojs-vr/dist/videojs-vr.css';

import Feature from 'ol/Feature';
import { format as formatCoords } from 'ol/coordinate';
import WKT from 'ol/format/WKT';
import Point from 'ol/geom/Point';
import { Vector } from 'ol/layer';
import { toDegrees, toRadians } from 'ol/math';
import { transform as transformProj } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from 'ol/style';
import Icon from 'ol/style/Icon';

import OverlaySpinner from '../presentational/common/OverlaySpinner';

import { getLineStyle } from './featureStyles';
import TimeOffsetTool from './TimeOffsetTool';

import Constants from '../../utils/constants';
import { setCoordinates } from '../../reducers/geovideoReducer';

import './VideoPlayer.scss';

const VideosContainer = styled.div`
  &.measuring div[data-measure='false'] {
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      cursor: not-allowed;
    }

    video {
      pointer-events: none;
    }
  }
`;

/* *****************************************************************************
 * MEDIÇÕES
 ****************************************************************************** */

const calculatePosition = (
  leftCoordinate,
  rightCoordinate,
  leftPixel,
  rightPixel,
  leftBeta,
  rightBeta,
  leftBearing,
  rightBearing,
) => {
  const [e1, n1] = leftCoordinate;
  const [x1] = leftPixel.map((v) => v * 3.6);
  const [e2, n2] = rightCoordinate;
  const [x2] = rightPixel.map((v) => v * 3.6);
  const R1 = toRadians(leftBearing);
  const R2 = toRadians(rightBearing);

  const beta1 = toRadians(leftBeta);
  const beta2 = toRadians(rightBeta);

  const r1 = R1 + beta1 + Math.atan((x1 - 960) / 1046);
  const r2 = R2 + beta2 + Math.atan((x2 - 960) / 1046);

  const n = (e2 - e1 + n1 * Math.tan(r1) - n2 * Math.tan(r2)) / (Math.tan(r1) - Math.tan(r2));
  const e = e2 + (n - n2) * Math.tan(r2);

  return [e, n];
};

/* ******************************************************************************
 * / MEDIÇÕES
 ****************************************************************************** */

const getBearing = (coord1, coord2) => {
  const [lon1, lat1] = coord1.map(toRadians);
  const [lon2, lat2] = coord2.map(toRadians);

  const y = Math.sin(lon2 - lon1) * Math.cos(lat2);
  const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1);

  const bearing = Math.atan2(y, x);

  return (toDegrees(bearing) + 360) % 360;
};

const setFeatureAtTime = (time, car, linestring, map, isLocked, rotateMap) => {
  const currentCoord = linestring.getGeometry().getCoordinateAtM(time, true);
  const nextCoord = linestring.getGeometry().getCoordinateAtM(time + 250e3, true);

  const bearing = getBearing(
    transformProj(currentCoord, map.projection.name, 'EPSG:4326'),
    transformProj(nextCoord, map.projection.name, 'EPSG:4326'),
  );

  car.setGeometry(new Point(currentCoord));

  if (rotateMap) {
    map.olMap.getView().setRotation(toRadians(-bearing));
  } else {
    car.getStyle().getImage().setRotation(toRadians(bearing));
  }

  if (isLocked) {
    map.olMap.getView().setCenter(car.getGeometry().getCoordinates());
  }
};

const getClickPosition = (evt) => {
  const rect = evt.currentTarget.getBoundingClientRect();

  return [evt.clientX - rect.left, evt.clientY - rect.top];
};

const oldGoPro = {
  FL: 'FL',
  FR: 'FR',
  CL: 'CL',
  CR: 'CR',
  BC: 'BC',
};
const newGoPro = {
  FL: 'Left',
  FR: 'Right',
  CL: 'Front',
  CR: 'Back',
};
const defaultOffset = 0;

const VideoPlayer = ({
  map,
  carTrack,
  onClose,
  onCarTrackSeen,
  visualizationProjectID,
  boundaryFeature,
  uploadMeasurePoints,
}) => {
  const dispatch = useDispatch();

  const availablePositions = carTrack.old_gopro ? Object.keys(oldGoPro) : Object.keys(newGoPro);
  const activeGoPro = carTrack.old_gopro ? oldGoPro : newGoPro;

  const coordinates = useSelector((state) => state.geovideo.coordinates);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(localStorage.getItem('fullscreen-mode') === 'true');
  const [currentPlayPercentage, setCurrentPlayPercentage] = useState(0);
  const [playSpeed, setPlaySpeed] = useState('1');
  const [lockCamera, setLockCamera] = useState(true);
  const [lockRotation, setLockRotation] = useState(false);
  const [zoomEnabledVideo, setZoomEnabledVideo] = useState(null);
  const [zoomedImage, setZoomedImage] = useState(null);
  const [visibleCameras, setVisibleCameras] = useState(
    localStorage.getItem('visible-cameras') ? JSON.parse(localStorage.getItem('visible-cameras')) : availablePositions,
  );
  const [videoOffsets, setVideoOffsets] = useState(() => {
    const savedOffsets = localStorage.getItem('videoOffsets');
    return savedOffsets ? JSON.parse(savedOffsets) : {};
  });

  // MEDIÇÕES

  const [measurements, setMeasurements] = useState([]);
  const [clickedPositions, setClickedPositions] = useState({});
  const [storedPositions, setStoredPositions] = useState([]);
  const [isMeasuring, setIsMeasuring] = useState(false);
  const [currentVideoTime, setCurrentVideoTime] = useState(null);
  const [targetVideoTime, setTargetVideoTime] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [tries, setTries] = useState(1);
  const measurementsLayer = useRef();

  // /MEDIÇÕES

  const carFeature = useRef();
  const trackLinestring = useRef();
  const carTrackLayer = useRef();
  const videoNodes = useRef({});
  const playerRef = useRef(null);

  useEffect(() => {
    Object.values(videoNodes.current).forEach((v) => {
      v.playbackRate = playSpeed;
    });
  }, [playSpeed, carTrack]);

  useEffect(() => {
    Object.values(videoNodes.current).forEach((v) => {
      // don't run this code if it's not a 360 video
      if (v.dataset['is-360'] === 'false') return;
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
      const player = (playerRef.current = videojs(v, {}, () => {
        // videojs.log('player is ready');
        handlePlayerReady && handlePlayerReady(player);
      }));
      // player.responsive(true);
      player.vr({ projection: '360', forceCardboard: false });
    });
  }, [videoNodes, carTrack]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      // if (player && !player.isDisposed()) {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  // Update video offsets
  const handleOffsetChange = (position, value) => {
    const newOffsets = { ...videoOffsets, [position]: value };
    setVideoOffsets(newOffsets);
    // Save the offsets to local storage to avoid having to figure the values everytime to sync the videos
    localStorage.setItem('videoOffsets', JSON.stringify(newOffsets));
  };

  useEffect(() => {
    // Update video times when offsets change
    const masterOffset = videoOffsets[carTrack.tracks[0].position] || defaultOffset;
    const masterTime = videoNodes.current[0].currentTime;

    Object.entries(videoNodes.current).forEach(([idx, node]) => {
      const track = carTrack.tracks[idx];
      const offset = videoOffsets[track.position] || defaultOffset;
      const adjustedTime = masterTime + (offset - masterOffset);

      node.currentTime = adjustedTime;
      node.dataset.offset = offset;
    });
  }, [videoOffsets, carTrack.tracks]);

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      // videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      // videojs.log('player will dispose');
    });
  };

  useEffect(() => {
    carFeature.current = new Feature({ id: 'car' });
    carFeature.current.setStyle(
      new Style({
        image: new Icon({
          src: '/arrowcar.png',
          imgSize: [64, 64],
        }),
      }),
    );

    carTrackLayer.current = new Vector({
      source: new VectorSource({
        features: [carFeature.current],
      }),
      map: map.olMap,
      style: getLineStyle(),
    });

    if (boundaryFeature != null) {
      carTrackLayer.current.getSource().addFeature(boundaryFeature);
    }

    const mapClickHandler = ({ pixel, coordinate }) => {
      const feature = map.olMap.forEachFeatureAtPixel(
        pixel,
        (featureAtPixel) => (featureAtPixel !== carFeature.current ? featureAtPixel : null),
        { layerFilter: (layer) => layer === carTrackLayer.current },
      );

      if (feature) {
        const timeAtClick = feature.getGeometry().getClosestPoint(coordinate)[2];

        if (Object.keys(videoNodes.current).length === 0) {
          return;
        }

        const masterVideo = videoNodes.current[[0]];

        masterVideo.currentTime =
          (timeAtClick - carTrack.first_utc_timestamp) / 1e6 + parseFloat(masterVideo.dataset.offset);

      }
    };

    map.olMap.on('singleclick', mapClickHandler);

    const linestring = new WKT().readFeature(carTrack.route, {
      featureProjection: map.projection.name,
      dataProjection: 'EPSG:4326',
    });

    linestring.set('seen', carTrack.seen);
    carTrackLayer.current.getSource().addFeature(linestring);

    trackLinestring.current = linestring;

    // MEDIÇÕES

    measurementsLayer.current = new Vector({
      source: new VectorSource({}),
      map: map.olMap,
      style: (feature) =>
        new Style({
          image: new CircleStyle({
            radius: 10,
            stroke: new Stroke({
              color: '#fff',
            }),
            fill: new Fill({
              color: '#3399CC',
            }),
          }),
          text: new Text({
            text: feature.get('name').toString(),
            fill: new Fill({
              color: '#fff',
            }),
          }),
        }),
    });

    // / MEDIÇÕES

    setIsPlaying(false);
    setCurrentPlayPercentage(0);

    return () => {
      carTrackLayer.current.setMap(null);
      map.olMap.un('singleclick', mapClickHandler);
      map.olMap.getView().setRotation(0);

      // MEDIÇÕES
      measurementsLayer.current.setMap(null);
      // / MEDIÇÕES
    };
  }, [boundaryFeature, carTrack, map.olMap, map.projection.name]);

  useEffect(() => {
   /**
    * Set main video currentTime to targetVideoTime
    * Set currentVideoTime if video hasn't yet buffered to the targetVideoTime
    */
    if (targetVideoTime != null) {
      if (parseInt(videoNodes.current[0].currentTime) < parseInt(targetVideoTime)) {
        videoNodes.current[0].currentTime = targetVideoTime;
        setCurrentVideoTime(videoNodes.current[0].currentTime);
      } else {
        setTargetVideoTime(null);
      }
    }
  }, [targetVideoTime])

  useEffect(() => {
    /**
     * This is supposed to imitate a recursive function
     * It is needed because we need to wait for the main video to buffer
     * to the specified targetVideoTime before we can set it's currentTime
     * This is supposed to block de UI until the video has buffered enough
     *
     * Set main video currentTime to targetVideoTime
     * Dependable on currentVideoTime state variable
     * If main video current time is lower than targetVideoTime,
     *  that means it has yet to buffer to the specified targetVideoTime
     *  So we incremente currentVideoTime state variable to the next tenth of a second
     *  To force this useEffect to run again
     *
     * It should run this useEffect until main video currentTime is the specified targetVideoTime
     * And then we clear the processing flag variable
     */
    if (tries < 10000) {
      if (currentVideoTime != null) {
        if (parseInt(videoNodes.current[0].currentTime) < parseInt(targetVideoTime)) {
          videoNodes.current[0].currentTime = 0;
          videoNodes.current[0].currentTime = targetVideoTime;
          setCurrentVideoTime(currentVideoTime + .1);
          setTries(tries+1);
        } else {
          setTargetVideoTime(null);
          setCurrentVideoTime(null);
        }
      }
    } else {
      alert('Ocorreu um erro ao abrir cartrack, tente novamente.')
    }
  }, [currentVideoTime, tries])

  const autoLoader = () => {

    if (coordinates && map && carTrack) {
      setProcessing(true);

      const { x, y, crs } = coordinates;

      // Convert lat/lon to the projection used by our map
      const convertedCoordinates = transformProj([x, y], crs, map.projection.name);

      // Use the trackLinestring to find the closest point
      if (trackLinestring.current) {
        const closestPoint = trackLinestring.current.getGeometry().getClosestPoint(convertedCoordinates);

        // Get the time of this point
        const timeAtClick = closestPoint[2];

        if (Object.keys(videoNodes.current).length === 0) {
          console.log('No video nodes available');
          return;
        }

        const masterVideo = videoNodes.current[0];
        const offset = parseFloat(masterVideo.dataset.offset) || defaultOffset;

        // Get the new time for the video with the offset
        const newCurrentTime = (timeAtClick - carTrack.first_utc_timestamp) / 1e6 + offset;

        setTargetVideoTime(newCurrentTime);
      } else {
        console.log('trackLinestring is not initialized');
      }
      dispatch(setCoordinates(null));
    }
  };

  const handleSeenVideoButton = (seen) => {
    const message = seen ? 'Quer marcar este vídeo como visto?' : 'Quer anular marcação deste vídeo como visto?';

    // eslint-disable-next-line no-alert
    if (window.confirm(message)) {
      axios
        .request({
          method: seen ? 'post' : 'delete',
          url: `${Constants.API_BASE_URL}/geovideo/visualization-projects-track-views/`,
          data: {
            visualization_project: visualizationProjectID,
            car_track: carTrack.id,
          },
        })
        .then(() => {
          onCarTrackSeen(carTrack.id, seen);
          carTrackLayer.current
            .getSource()
            .getFeatures()
            .forEach((feat) => feat.set('seen', seen));
        });
    }
  };

  const getOriginalFrameForTime = (video, time, position) => {
    if (!position) {
      // Open frame viewer in a new window
      const frameViewerUrl = `/geovideo/frame-viewer/?track=${video}&time=${time}`;
      window.open(frameViewerUrl);
    } else {
      // Set zoomed image with the original URL structure
      const frameUrl = `${Constants.API_BASE_URL}/geovideo/get-original-frame/?track=${video}&time=${time}&position=${position.join(',')}`;
      setZoomedImage(frameUrl);
    }
  };

  const widgetElement = document.getElementById('geovideo-widget');

  /* ******************************************************************************
   * MEDIÇÕES
   ****************************************************************************** */

  const handleMeasureButtonClick = () => {
    if (!isMeasuring) {
      videoNodes.current[[0]].pause();
    } else {
      setClickedPositions({});
      setMeasurements([]);

      measurementsLayer.current.getSource().clear();
    }

    setIsMeasuring(!isMeasuring);
  };

  /* ******************************************************************************
   * / MEDIÇÕES
   ****************************************************************************** */

  const registerMeasurePoints = () => {
    uploadMeasurePoints(measurements);
    handleMeasureButtonClick();
  };

  // Button and events functions

  const videoTimeUpdate = (idx, target) => {
    if (targetVideoTime == null && currentVideoTime == null) {
      if (idx === 0) {
        const offset = videoOffsets[carTrack.tracks[0].position] || defaultOffset;
        const currentUtcTime = carTrack.first_utc_timestamp + target.currentTime * 1e6 - offset * 1e6;

        setFeatureAtTime(currentUtcTime, carFeature.current, trackLinestring.current, map, lockCamera, lockRotation);

        setCurrentPlayPercentage(((target.currentTime - offset) / carTrack.duration) * 100);

        if (processing == true) {
          setProcessing(false);
        }
      }
    }
  };

  const videoSeek = (idx, target) => {
    if (idx === 0) {
      const currentTime = target.currentTime;
      const masterOffset = videoOffsets[carTrack.tracks[0].position] || defaultOffset;
      const currentUtcTime = carTrack.first_utc_timestamp + currentTime * 1e6 - masterOffset * 1e6;

      setFeatureAtTime(currentUtcTime, carFeature.current, trackLinestring.current, map, lockCamera, lockRotation);

      Object.entries(videoNodes.current)
        .filter(([key]) => key !== '0')
        .forEach(([key, videoNode]) => {
          const trackIndex = parseInt(key, 10);
          const trackPosition = carTrack.tracks[trackIndex].position;
          const nodeOffset = videoOffsets[trackPosition] || defaultOffset;
          videoNode.currentTime = currentTime + (nodeOffset - masterOffset);
        });
    }
  };

  const videoPlay = (idx) => {
    if (idx === 0) {
      const masterVideo = videoNodes.current[0];
      const masterOffset = videoOffsets[carTrack.tracks[0].position] || defaultOffset;
      const masterTime = masterVideo.currentTime;

      Object.entries(videoNodes.current)
        .filter(([key]) => key !== '0')
        .forEach(([key, videoNode]) => {
          const trackIndex = parseInt(key, 10);
          const trackPosition = carTrack.tracks[trackIndex].position;
          const nodeOffset = videoOffsets[trackPosition] || defaultOffset;
          videoNode.currentTime = masterTime + (nodeOffset - masterOffset);
          const playPromise = videoNode.play();
          if (playPromise !== undefined) {
            playPromise.catch((error) => {
              console.log('Play ERROR:', error);
            });
          }
        });
      setIsPlaying(true);
      return true;
    }
  };

  const videoPause = (idx, target) => {
    if (idx === 0) {
      const currentTime = target.currentTime;
      Object.entries(videoNodes.current).forEach(([key, videoNode]) => {
        if (key !== '0') {
          // Skip the master video (index 0)
          videoNode.pause();
        }
      });
      setIsPlaying(false);
      return true;
    }
  };

  const videoClick = (evt, track, idx) => {
    const position = getClickPosition(evt);
    const [xPos, yPos] = position;
    const { width: videoWidth, height: videoHeight } = evt.currentTarget.getBoundingClientRect();

    if (track.id === zoomEnabledVideo) {
      const positionPercentage = [xPos / videoWidth, yPos / videoHeight];

      getOriginalFrameForTime(track.id, evt.currentTarget.currentTime, positionPercentage);
    }

    // MEDIÇÃO
    if (isMeasuring) {
      const offset = videoOffsets[track.position] || defaultOffset;
      const currentTime = evt.currentTarget.currentTime;

      const utcTime = carTrack.first_utc_timestamp + (currentTime - offset) * 1e6;

      let currentCoord = trackLinestring.current.getGeometry().getCoordinateAtM(utcTime, true);
      let nextCoord = trackLinestring.current.getGeometry().getCoordinateAtM(utcTime + 250e3, true);

      const bearing = getBearing(
        transformProj(currentCoord, map.projection.name, 'EPSG:4326'),
        transformProj(nextCoord, map.projection.name, 'EPSG:4326'),
      );

      const betas = {
        FL: -90, // Left angle
        FR: 90, // Right angle
        CL: 0, // Front angle
        CR: 180, // Back angle
      };

      const currentBeta = betas[track.position];

      if (currentBeta === undefined) {
        throw new Error('Missing beta for this camera!');
      }

      const values = {
        coordinates: transformProj(currentCoord, map.projection.name, 'EPSG:3857'),
        xc: videoWidth / 2,
        position: getClickPosition(evt),
        beta: currentBeta,
        bearing,
      };

      setClickedPositions({
        ...clickedPositions,
        [idx]: values,
      });
    }
  };

  const originalFrameClick = (currentTarget, track) => {
    if (isPlaying) {
      return;
    }

    const video = currentTarget.parentNode.querySelector('video');

    getOriginalFrameForTime(track.id, video.currentTime);
  };

  const magnifyClick = (track) => {
    if (isPlaying) {
      return;
    }

    setZoomedImage(null);
    setZoomEnabledVideo(zoomEnabledVideo !== track.id ? track.id : null);
  };

  const openOnGoogleMaps = () => {
    const geometry = carFeature.current.getGeometry();

    if (!geometry) {
      return;
    }

    const currentCoords = transformProj(
      carFeature.current.getGeometry().getCoordinates(),
      map.projection.name,
      'EPSG:4326',
    );

    window.open(`http://www.google.com/maps/place/${formatCoords(currentCoords, '{y},{x}', 5)}`, '_blank');
  };

  const confirmMeasurements = () => {
    const allPositions = [...storedPositions, ...Object.values(clickedPositions)];

    setClickedPositions({});

    if (allPositions.length < 2) {
      setStoredPositions(allPositions);

      return;
    }

    const [pos1, pos2] = allPositions;

    const measure = transformProj(
      calculatePosition(
        pos1.coordinates,
        pos2.coordinates,
        pos1.position,
        pos2.position,
        pos1.beta,
        pos2.beta,
        pos1.bearing,
        pos2.bearing,
      ),
      'EPSG:3857',
      map.projection.name,
    );

    measurementsLayer.current.getSource().addFeature(
      new Feature({
        geometry: new Point(measure),
        name: measurements.length + 1,
      }),
    );

    setMeasurements([...measurements, measure]);

    setStoredPositions([]);
  };

  const cameraToggle = (event, position) => {
    const newCameras = event.currentTarget.checked
      ? [...visibleCameras, position]
      : visibleCameras.filter((p) => p !== position);

    localStorage.setItem('visible-cameras', JSON.stringify(newCameras));

    setVisibleCameras(newCameras);
  };

  const playPause = () => {
    const masterVideo = videoNodes.current[0];
    if (masterVideo === null) return;

    if (isPlaying) {
      masterVideo.pause();
      // Other videos are paused in the onPause handler
    } else {
      const playPromise = masterVideo.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            // Play other videos at their current times
            Object.entries(videoNodes.current).forEach(([key, node]) => {
              if (key !== '0') {
                node.play().catch((error) => {
                  console.log('Play ERROR:', error);
                });
              }
            });
          })
          .catch((error) => {
            console.log('Play ERROR:', error);
          });
      }
    }
    setIsPlaying(!isPlaying);
  };

  const rewind = () => {
    const masterVideo = videoNodes.current[[0]];

    masterVideo.currentTime -= 2;
    masterVideo.pause();

    if (isPlaying) {
      const playPromise = masterVideo.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {})
          .catch((error) => {
            console.log('Play ERROR:', error);
          });
      }
    }
  };

  const fastforward = () => {
    const masterVideo = videoNodes.current[[0]];

    masterVideo.currentTime += 2;
    masterVideo.pause();

    if (isPlaying) {
      const playPromise = masterVideo.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {})
          .catch((error) => {
            console.log('Play ERROR:', error);
          });
      }
    }
  };

  const previousFrame = () => {
    const masterVideo = videoNodes.current[[0]];

    masterVideo.pause();
    masterVideo.currentTime = Math.max(0, masterVideo.currentTime - 1 / 59.94);
  };

  const nextFrame = () => {
    const masterVideo = videoNodes.current[[0]];

    masterVideo.pause();
    masterVideo.currentTime = Math.min(masterVideo.duration, masterVideo.currentTime + 1 / 59.94);
  };

  const changeRotationLock = () => {
    if (!lockRotation) {
      carFeature.current.getStyle().getImage().setRotation(0);
    } else {
      map.olMap.getView().setRotation(0);
    }

    setLockRotation(!lockRotation);
  };

  const fullScreenToggle = () => {
    localStorage.setItem('fullscreen-mode', !isFullscreen);
    setIsFullscreen(!isFullscreen);
  };

  const changeVideoPercentage = (event) => {
    const playPercentage = event.currentTarget.value / 100;
    const masterVideo = videoNodes.current[[0]];

    masterVideo.currentTime = masterVideo.duration * playPercentage;
    setCurrentPlayPercentage(playPercentage);
  };

  return ReactDOM.createPortal(
    <VideosContainer className={`videoContainer ${isMeasuring ? 'measuring' : ''}`}>
      {processing && <OverlaySpinner />}
      <div className={'carTracksContainer'}>
        {carTrack.tracks.map((track, idx) => (
          <div
            className={'singleCarTrack'}
            data-measure={track.frequency}
            key={track.id}
            style={{
              gridArea: track.position,
              display: visibleCameras.indexOf(track.position) !== -1 ? 'initial' : 'none',
            }}
          >
            {track.id === zoomEnabledVideo && zoomedImage && (
              <div className={'zoomedImg'}>
                <img src={zoomedImage} alt="" />
                <button
                  className={'closeZoom'}
                  type="button"
                  onClick={() => {
                    setZoomedImage(null);
                  }}
                >
                  <i className="fas fa-times" />
                </button>
              </div>
            )}
            <div className={`singleVideo ${isFullscreen ? 'fullScreen' : ''}`}>
              <video
                data-is-360={track.is_360}
                {...(track.is_360
                  ? {
                      className: 'video-js',
                      width: '1024',
                      height: '512',
                    }
                  : {})}
                crossOrigin="anonymous"
                style={{
                  padding: '0 5px',
                  ...(isFullscreen
                    ? {
                        width: '100%',
                        maxWidth: '1024px',
                      }
                    : { maxWidth: '100%' }),
                }}
                data-offset={videoOffsets[track.position] || defaultOffset}
                muted
                onCanPlayThrough={() => autoLoader()}
                onTimeUpdate={({ target }) => videoTimeUpdate(idx, target)}
                onSeeked={({ target }) => videoSeek(idx, target)}
                onPlay={() => videoPlay(idx)}
                onPause={(target) => videoPause(idx, target)}
                ref={(videoNode) => {
                  videoNodes.current[[idx]] = videoNode;
                }}
                onClick={(evt) => videoClick(evt, track, idx)}
              >
                {track.is_360 ? (
                  <source src={track.original_video} type="video/mp4" />
                ) : (
                  <source src={track.webm} type="video/webm" />
                )}
              </video>
              <button
                className={'frameButton'}
                type="button"
                onClick={({ currentTarget }) => originalFrameClick(currentTarget, track)}
              >
                <i className="fas fa-image" />
              </button>
              <button
                className={`magnifyButton ${track.id === zoomEnabledVideo ? 'magnifyActive' : 'magnifyRegular'}`}
                type="button"
                onClick={() => magnifyClick(track)}
              >
                <i className="fas fa-search-plus" />
              </button>
            </div>
            {
              /* MEDIÇÃO */
              isMeasuring && clickedPositions[[idx]] && (
                <i
                  className="fas fa-crosshairs measure"
                  style={{
                    left: `${clickedPositions[[idx]].position[0]}px`,
                    top: `${clickedPositions[[idx]].position[1]}px`,
                  }}
                />
              )
              /* / MEDIÇÃO */
            }
          </div>
        ))}
      </div>
      <div className={'controlBar'}>
        <button type="button" onClick={() => onClose()}>
          Fechar
        </button>
        <button type="button" onClick={() => handleSeenVideoButton(!carTrack.seen)}>
          {carTrack.seen ? 'Anular marcação de video como visto' : 'Marcar video como visto'}
        </button>
        <button type="button" onClick={() => openOnGoogleMaps()}>
          Google Maps
        </button>
        {/* MEDIÇÃO */}
        <div>
          <div className="measurements">
            <button type="button" onClick={() => handleMeasureButtonClick()}>
              {isMeasuring ? 'Desativar medições' : 'Ativar medições'}
            </button>
            {isMeasuring && Object.keys(clickedPositions).length > 0 && (
              <button type="button" onClick={() => confirmMeasurements()}>
                Confirmar Marcações
              </button>
            )}
            {isMeasuring && (
              <div>
                {measurements.length > 0 && (
                  <ol>
                    {measurements
                      .map((m) => formatCoords(m, '{y}, {x}', 5))
                      // eslint-disable-next-line react/no-array-index-key
                      .map((m, idx) => (
                        <li key={idx}>{m}</li>
                      ))}
                  </ol>
                )}
              </div>
            )}
            {measurements.length > 0 && (
              <div>
                <button type="button" onClick={() => registerMeasurePoints(measurements)}>
                  Registar medições
                </button>
              </div>
            )}
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <TimeOffsetTool
            videoOffsets={videoOffsets}
            onOffsetChange={handleOffsetChange}
            availablePositions={availablePositions}
            activeGoPro={activeGoPro}
          />
        </div>
        {/* / MEDIÇÃO */}
        <div className={'cameraCheck'}>
          {availablePositions.map((position, label) => (
            <div key={position} style={{ marginLeft: '10px' }}>
              <input
                id={position}
                type="checkbox"
                value={position}
                checked={visibleCameras.indexOf(position) !== -1}
                onChange={(event) => cameraToggle(event, position)}
              />
              <label htmlFor={position}>
              {activeGoPro[position]}
              {carTrack.tracks[0].position === position && ' (Master)'}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="controls controlsContainer">
        <button type="button" onClick={() => playPause()}>
          {isPlaying ? <i className="fas fa-pause" /> : <i className="fas fa-play" />}
        </button>
        <button type="button" onClick={() => rewind()}>
          -2
          <i className="fas fa-undo" />
        </button>
        <button type="button" onClick={() => fastforward()}>
          <i className="fas fa-redo" />
          +2
        </button>
        <select
          onChange={(event) => {
            setPlaySpeed(event.currentTarget.value);
          }}
          value={playSpeed}
        >
          <option value="0.1">0.1x</option>
          <option value="0.3">0.3x</option>
          <option value="0.5">0.5x</option>
          <option value="0.7">0.7x</option>
          <option value="1">1x</option>
          <option value="1.5">1.5x</option>
          <option value="2">2x</option>
        </select>
        <button type="button" onClick={() => previousFrame()}>
          <i className="fas fa-step-backward" />
        </button>
        <button type="button" onClick={() => nextFrame()}>
          <i className="fas fa-step-forward" />
        </button>
        <button type="button" onClick={() => setLockCamera(!lockCamera)}>
          <i className={lockCamera ? 'fas fa-lock' : 'fas fa-lock-open'} />
        </button>
        <button type="button" onClick={() => changeRotationLock()}>
          <i className={lockRotation ? 'fas fa-compass' : 'fas fa-arrow-up'} />
        </button>
        <button type="button" onClick={() => fullScreenToggle()}>
          {isFullscreen ? <i className="fas fa-compress" /> : <i className="fas fa-expand" />}
        </button>
        <input
          style={{ flex: 1 }}
          type="range"
          step="any"
          value={currentPlayPercentage}
          disabled={isMeasuring}
          onChange={(event) => changeVideoPercentage(event)}
        />
      </div>
    </VideosContainer>,
    widgetElement,
  );
};

export default VideoPlayer;
