import React, { useEffect } from 'react';
import { useParams, useLocation, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { fetchCarTrack } from '../thunks/geoVideoThunk';
import { selectTool } from '../reducers/sidebarReducer';
import { setCoordinates } from '../reducers/geovideoReducer';

function AutoOpenCarTrackContainer() {

    const { carTrackId } = useParams();
    const {mapSummary, carTrack, visualizationProject, error} = useSelector(state => state.geovideo);
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const x = searchParams.get('x');
      const y = searchParams.get('y');
      const crs = searchParams.get('crs')

      if (x && y && crs) {
        dispatch(setCoordinates({ x: parseFloat(x), y: parseFloat(y), crs }));
      }

      dispatch(fetchCarTrack(carTrackId));
    }, []);

    useEffect(() => {
      // If fetch succeeded open Geovideo SideMenu
      if (mapSummary != null && carTrack != null && visualizationProject != null) {
        dispatch(selectTool("Geovideo"));
      }

    }, [mapSummary, carTrack, visualizationProject]);

    // If fetch succeeded redirect to map
    if (mapSummary != null) {
      return <Redirect to={`/mapas/${mapSummary.slug}`}/>;
    }

    return (<>
      {error ? <div>Not Found</div>: <div></div>}
    </>)
  };

export default AutoOpenCarTrackContainer;
